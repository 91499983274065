import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, IconButton, Container, Paper, Slider, List, 
  ListItem, ListItemText, Link, Button, Collapse, Fade,
  Stack, ButtonGroup
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuBar from './components/MenuBar';
import { ReactComponent as Logo } from './assets/customcast/White logo - no background.svg';
import { trackEvent, logArticleClick } from './utils/analytics';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const PlaySummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [showArticles, setShowArticles] = useState(false);
  const { isDemoMode } = location.state || {};
  const { summarized_news, audio_file_url, articles, timestamps } = location.state || {};

  // Add ref to ensure we only fire once
  const summaryEventFired = useRef(false);

  // Move updateProgress definition before its usage
  const updateProgress = useCallback(() => {
    const audio = audioRef.current;
    if (audio) {
      const currentTime = audio.currentTime;
      setProgress((currentTime / audio.duration) * 100);
    }
  }, []);

  // 1. First useEffect - Audio player setup
  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current;

      const handleTimeUpdate = () => {
        setProgress(audio.currentTime);
      };

      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      const handleEnded = () => {
        trackEvent('audio_complete', {
          player_type: 'audio_player',
          duration: duration
        });
        setIsAudioPlaying(false);
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [duration]);

  // 2. Second useEffect - Data validation and navigation
  useEffect(() => {
    if (!summarized_news || !audio_file_url || !articles || !timestamps) {
      console.error('Missing required data for PlaySummary');
      navigate('/');
      return;
    }

    const audio = audioRef.current;
    if (audio) {
      const handleMetadata = () => setDuration(audio.duration);
      audio.addEventListener('loadedmetadata', handleMetadata);
      audio.addEventListener('timeupdate', updateProgress);

      return () => {
        audio.removeEventListener('loadedmetadata', handleMetadata);
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }
  }, [summarized_news, audio_file_url, articles, timestamps, navigate, updateProgress]);

  // Update the useEffect that fires summary_ready
  useEffect(() => {
    if (!summaryEventFired.current && summarized_news && audio_file_url && articles && timestamps) {
      trackEvent('summary_ready', {
        total_articles: articles.length,
        audio_duration: duration,
        categories: location.state?.selectedCategories?.join(',') || 'unknown'
      });
      summaryEventFired.current = true;
    }
  }, [summarized_news, audio_file_url, articles, timestamps, duration, location.state?.selectedCategories]);

  // Now we can have our conditional return
  if (!summarized_news || !audio_file_url || !articles || !timestamps) {
    return null;
  }

  const handleSkip = (seconds) => {
    const audio = audioRef.current;
    if (audio) {
      const newTime = Math.min(Math.max(0, audio.currentTime + seconds), audio.duration);
      audio.currentTime = newTime;
      
      trackEvent('audio', {
        action: 'skip',
        direction: seconds > 0 ? 'forward' : 'backward',
        skip_amount: Math.abs(seconds)
      });
    }
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isAudioPlaying) {
        audio.pause();
        trackEvent('audio', {
          action: 'pause',
          current_time: audio.currentTime,
          duration: audio.duration
        });
      } else {
        audio.play();
        trackEvent('audio', {
          action: 'play',
          current_time: audio.currentTime,
          duration: audio.duration
        });
      }
      setIsAudioPlaying(!isAudioPlaying);
    }
  };

  // Format time for display (convert seconds to MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleSliderChange = (event, newValue) => {
    const audio = audioRef.current;
    if (audio) {
      const newTime = (newValue / 100) * audio.duration;
      audio.currentTime = newTime;
      setProgress(newValue);
      
      trackEvent('audio_progress_change', {
        new_position: newTime,
        duration: audio.duration
      });
    }
  };

  // Track feedback and demo clicks
  const handleFeedbackClick = () => {
    trackEvent('feedback_click', {
      location: 'play_summary',
      button_type: 'feedback'
    });
  };

  const handleDemoClick = () => {
    trackEvent('demo_click', {
      location: 'play_summary',
      button_type: 'business_demo'
    });
  };

  const handleRestartDemo = () => {
    trackEvent('demo_restart', {
      location: 'play_summary'
    });
    navigate('/demo');
  };

  const handleBetaRegistration = () => {
    trackEvent('beta_registration', {
      location: 'play_summary',
      button_type: 'registration'
    });
  };

  // Update article links
  const renderArticleList = () => (
    <List disablePadding>
      {articles.map((article, index) => (
        <ListItem 
          key={index}
          disableGutters
          sx={{
            borderBottom: index < articles.length - 1 ? '1px solid rgba(0, 0, 0, 0.08)' : 'none',
            py: 1.5
          }}
        >
          <ListItemText
            primary={
              <Link 
                href={article.link} 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={(e) => logArticleClick(article, index)}
                onAuxClick={(e) => {  // Handle middle-click
                  if (e.button === 1) {  // Middle mouse button
                    logArticleClick(article, index);
                  }
                }}
                onContextMenu={(e) => {  // Handle right-click
                  logArticleClick(article, index);
                }}
                sx={{ 
                  color: '#632382',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {article.title}
              </Link>
            }
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {!isDemoMode && <MenuBar />}
      <Container 
        maxWidth="sm" 
        sx={{ 
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pt: isDemoMode ? 4 : 8,
          pb: 4
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            backgroundColor: 'background.paper',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            mt: isDemoMode ? 4 : 8
          }}
        >
          {isDemoMode && (
            <IconButton
              onClick={handleRestartDemo}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                color: '#632382',
                '&:hover': {
                  backgroundColor: 'rgba(99, 35, 130, 0.04)',
                }
              }}
              aria-label="restart demo"
            >
              <RestartAltIcon />
            </IconButton>
          )}

          <Fade in={true} timeout={800}>
            <Box>
              {/* Player Header */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 3,
                gap: 2
              }}>
                <Paper
                  elevation={2}
                  sx={{
                    width: 120,
                    height: 120,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#632382',
                    borderRadius: 2
                  }}
                >
                  <Logo style={{ width: 100, height: 'auto' }} />
                </Paper>
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: 'text.secondary',
                      mb: 0.5
                    }}
                  >
                    NOW PLAYING
                  </Typography>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      mb: 0.5
                    }}
                  >
                    Your News Summary
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ color: 'text.secondary' }}
                  >
                    CustomCast
                  </Typography>
                </Box>
              </Box>

              {/* Progress Bar */}
              <Box sx={{ mb: 2 }}>
                <Slider
                  value={progress}
                  onChange={handleSliderChange}
                  max={duration}
                  sx={{
                    color: '#632382',
                    height: 4,
                    '& .MuiSlider-thumb': {
                      width: 12,
                      height: 12,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px rgb(99 35 130 / 16%)`
                      },
                      '&.Mui-active': {
                        width: 16,
                        height: 16,
                      },
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28,
                    }
                  }}
                />
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  px: 0.5
                }}>
                  <Typography variant="caption" color="text.secondary">
                    {formatTime(progress)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatTime(duration)}
                  </Typography>
                </Box>
              </Box>

              {/* Playback Controls */}
              <Stack 
                direction="row" 
                spacing={2} 
                justifyContent="center" 
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <IconButton 
                  onClick={() => handleSkip(-10)}
                  sx={{ color: 'text.secondary' }}
                >
                  <Replay10Icon />
                </IconButton>
                
                <IconButton 
                  onClick={togglePlay}
                  sx={{ 
                    width: 56, 
                    height: 56,
                    backgroundColor: '#632382',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#44185a',
                    },
                    boxShadow: '0 4px 12px rgba(99, 35, 130, 0.4)'
                  }}
                >
                  {isAudioPlaying ? 
                    <PauseIcon sx={{ fontSize: 32 }} /> : 
                    <PlayArrowIcon sx={{ fontSize: 32 }} />
                  }
                </IconButton>
                
                <IconButton 
                  onClick={() => handleSkip(10)}
                  sx={{ color: 'text.secondary' }}
                >
                  <Forward10Icon />
                </IconButton>
              </Stack>

              {/* Articles Section */}
              <Box sx={{ mt: 2 }}>
                <Button
                  onClick={() => setShowArticles(!showArticles)}
                  endIcon={showArticles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  fullWidth
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'rgba(99, 35, 130, 0.08)'
                    }
                  }}
                >
                  {showArticles ? 'Hide Articles' : 'Show Articles'}
                </Button>

                <Collapse in={showArticles} timeout={500}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      mt: 2,
                      p: 2,
                      backgroundColor: 'rgba(0, 0, 0, 0.02)',
                      borderRadius: 2
                    }}
                  >
                    {renderArticleList()}
                  </Paper>
                </Collapse>
              </Box>

              {/* Action Buttons */}
              <Box sx={{ mt: 4 }}>
                <ButtonGroup
                  variant="contained"
                  fullWidth
                  sx={{
                    '& .MuiButton-root': {
                      flex: 1,
                      py: 1.5,
                      backgroundColor: '#632382',
                      '&:hover': {
                        backgroundColor: '#44185a',
                      },
                      textTransform: 'none',
                    },
                    '& .MuiButton-outlined': {
                      borderColor: '#632382',
                      color: '#632382',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(99, 35, 130, 0.04)',
                        borderColor: '#44185a',
                      },
                    }
                  }}
                >
                  <Button
                    href="https://forms.gle/ZdZqM2FtUowbi1T6A"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleFeedbackClick}
                  >
                    Feedback
                  </Button>
                  <Button
                    href="https://doai.co.nz/customcast"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleDemoClick}
                  >
                    Business Demo
                  </Button>
                  <Button
                    href="https://forms.gle/mL7bayuEa8yND6S56"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleBetaRegistration}
                  >
                    Beta Registration
                  </Button>
                </ButtonGroup>
              </Box>

              {/* Audio Element */}
              <audio
                ref={audioRef}
                src={audio_file_url}
                onEnded={() => setIsAudioPlaying(false)}
                style={{ display: 'none' }}
              />
            </Box>
          </Fade>
        </Paper>
      </Container>
    </>
  );
};

export default PlaySummary;
