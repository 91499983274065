import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { Typography, Container, CircularProgress, Paper, List, ListItem, ListItemText } from '@mui/material';
import MenuBar from './components/MenuBar';
import { makeAuthenticatedRequest } from './utils/apiService';

const ProfilePage = () => {
  const { user } = useUser();
  const [userState, setUserState] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user && user.uid) {
        try {
          setLoading(true);
          setError(null);

          // Fetch user state
          const stateResponse = await makeAuthenticatedRequest(
            process.env.REACT_APP_USER_STATE_ENDPOINT,
            {
              method: 'GET'
            }
          );

          const stateData = await stateResponse.json();
          setUserState(stateData);

          // Fetch user preferences
          const preferencesResponse = await makeAuthenticatedRequest(
            process.env.REACT_APP_USER_PREFERENCES_ENDPOINT,
            {
              method: 'GET'
            }
          );

          if (preferencesResponse.status === 404) {
            setUserPreferences(null);
          } else {
            const preferencesData = await preferencesResponse.json();
            setUserPreferences(preferencesData);
          }

        } catch (error) {
          console.error('Error fetching user info:', error);
          setError('Failed to fetch user information. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserInfo();
  }, [user]);

  const renderContent = () => (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      {userState && (
        <Typography variant="h6" gutterBottom>
          Membership Tier: {userState.membershipTier}
        </Typography>
      )}
      
      {userPreferences ? (
        <>
          <Typography variant="h6" gutterBottom>
            Preferred Categories:
          </Typography>
          <List>
            {userPreferences.categories.map((category, index) => (
              <ListItem key={index}>
                <ListItemText primary={category} />
              </ListItem>
            ))}
          </List>
          <Typography variant="body1">
            Last Summary: {new Date(userPreferences.lastSummary).toLocaleString()}
          </Typography>
        </>
      ) : (
        <Typography variant="body1">
          No history of summaries available for this user.
        </Typography>
      )}
    </Paper>
  );

  return (
    <>
      <MenuBar />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>User Profile</Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          renderContent()
        )}
      </Container>
    </>
  );
};

export default ProfilePage;
