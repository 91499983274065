import React, { useState, useEffect, useRef } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from './UserContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleIcon } from './CustomIcons';
import Alert from '@mui/material/Alert';
import { makeAuthenticatedRequest } from './utils/apiService';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

const LoginContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
}));

const Login = () => {
  const { updateUser } = useUser();
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const initializeUserInBackend = async (uid) => {
    try {
      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_USER_STATE_ENDPOINT,
        {
          method: 'GET'
        }
      );

      return await response.json();
    } catch (error) {
      console.error('Error initializing user in backend:', error);
      throw error;
    }
  };

  const handleUserAuthentication = async (user) => {
    try {
      const userData = await initializeUserInBackend(user.uid);
      if (userData) {
        updateUser({
          uid: user.uid,
          email: user.email,
          name: userData.name || user.displayName,
          membershipTier: userData.membershipTier || 'Free',
          isAuthenticated: true,
        });
        navigate('/');
      }
    } catch (error) {
      if (error.message === 'User not found') {
        await auth.signOut();
        navigate('/signup', { state: { error: 'Account not found. Please sign up.' } });
      } else {
        throw error;
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userExists = await checkUserExistsInBackend(userCredential.user.uid);
      
      if (!userExists) {
        await auth.currentUser.delete();
        setLoginError(
          <Alert 
            severity="error" 
            sx={{ 
              width: '100%',
              '& a': {
                color: 'primary.main',
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          >
            User not authorized. Please{' '}
            <Link 
              to="/signup" 
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              sign up
            </Link>
            {' '}if you are approved for the beta programme or{' '}
            <Link
              href="https://doai.co.nz/customcast"
              target="_blank"
              rel="noopener noreferrer"
            >
              get in touch
            </Link>
            {' '}for access.
          </Alert>
        );
        return;
      }
      await handleUserAuthentication(userCredential.user);
    } catch (error) {
      console.error('Login error:', error);
      if (error.code === 'auth/unauthorized-domain' || 
          error.code === 'auth/operation-not-allowed') {
        setLoginError(
          <Alert 
            severity="error" 
            sx={{ 
              width: '100%',
              '& a': {
                color: 'primary.main',
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          >
            User not authorized. Please{' '}
            <Link 
              to="/signup" 
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              sign up
            </Link>
            {' '}if you are approved for the beta programme or{' '}
            <Link
              href="https://doai.co.nz/customcast"
              target="_blank"
              rel="noopener noreferrer"
            >
              get in touch
            </Link>
            {' '}for access.
          </Alert>
        );
      } else {
        setEmailError(true);
        setEmailErrorMessage('Invalid email or password. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await handleUserAuthentication(result.user);
    } catch (error) {
      console.error('Google Sign-In error:', error);
      setLoginError('Unable to sign in with Google. Please try again.');
    }
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  const checkUserExistsInBackend = async (uid) => {
    try {
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_USER_STATE_ENDPOINT}/${uid}`,
        {
          method: 'GET'
        }
      );

      return true;
    } catch (error) {
      if (error.message.includes('404')) {
        return false;
      }
      console.error('Error checking user existence:', error);
      throw error;
    }
  };

  return (
    <>
      <CssBaseline />
      <LoginContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign in
          </Typography>
          {loginError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {loginError}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="email"
                variant="outlined"
                error={emailError}
                helperText={emailErrorMessage}
                color={emailError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              Sign In
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              Don't have an account?{' '}
              <Link to="/signup" variant="body2">
                Sign up
              </Link>
            </Typography>
          </Box>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>or</Typography>
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignIn}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            {/*<Button
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign in with Facebook')}
              startIcon={<FacebookIcon />}
            >
              Sign in with Facebook
            </Button>*/}
          </Box>
        </Card>
      </LoginContainer>
    </>
  );
};

export default Login;
