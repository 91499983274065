import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Login from './Login';
import Home from './Home';
import { Onboarding } from './Onboarding';
import Signup from './Signup';
import PlaySummary from './PlaySummary';
import ProfilePage from './ProfilePage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { initGA } from './utils/analytics';

import { auth } from './firebase/firebaseConfig';

const AppRoutes = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, () => {
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/demo" element={<Onboarding isDemoMode={true} />} />
      <Route path="/login" element={!user || user.email === process.env.REACT_APP_DEMO_USER_EMAIL ? <Login /> : <Navigate to="/" />} />
      <Route path="/signup" element={!user || user.email === process.env.REACT_APP_DEMO_USER_EMAIL ? <Signup /> : <Navigate to="/onboarding" />} />
      
      {/* Protected Routes */}
      <Route path="/onboarding" element={
        <ProtectedRoute user={user}>
          <Onboarding isDemoMode={false} />
        </ProtectedRoute>
      } />
      <Route path="/" element={
        <ProtectedRoute user={user}>
          <Home />
        </ProtectedRoute>
      } />
      <Route path="/play-summary" element={
        <ProtectedRoute user={user} allowDemo={true}>
          <PlaySummary />
        </ProtectedRoute>
      } />
      <Route path="/profile" element={
        <ProtectedRoute user={user}>
          <ProfilePage />
        </ProtectedRoute>
      } />
    </Routes>
  );
};

const ProtectedRoute = ({ user, allowDemo = false, children }) => {
  const location = useLocation();
  const isDemoMode = location.state?.isDemoMode;
  const isAuthenticated = location.state?.authenticated;
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        await new Promise(resolve => setTimeout(resolve, 1500));
      }
      setIsChecking(false);
    };
    checkAuth();
  }, [user]);

  if (isChecking) {
    return <CircularProgress />;
  }

  if (user && user.email === process.env.REACT_APP_DEMO_USER_EMAIL && !allowDemo) {
    return <Navigate to="/demo" state={{ from: location }} replace />;
  }

  if (!user && !isDemoMode && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    
    // Initialize GA4
    initGA();
    document.title = 'CustomCast';
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <Router>
          <AppRoutes />
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
