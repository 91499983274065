import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import stuffLogo from './assets/stuff-logo.png';
import spinoffLogo from './assets/spinoff-logo.png';
import { Typography, Button, Container, Grid, 
  Card, CardContent, CardActions, IconButton, Box, CircularProgress,
  Chip, Paper
} from '@mui/material';
import { PlayArrow, Refresh as RefreshIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import MenuBar from './components/MenuBar';
import LoadingScreen from './LoadingScreen';
import PlaySummary from './PlaySummary';
import { useUser } from './UserContext';
import { makeAuthenticatedRequest } from './utils/apiService';


const MAIN_CATEGORIES = ['nz-news', 'world-news', 'politics', 'environment', 'sport'];

const Home = ({ isDemoMode }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [summarizedNews, setSummarizedNews] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSource, setSelectedSource] = useState('Stuff');
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [showOtherCategories, setShowOtherCategories] = useState(false);
  const [currentStep, setCurrentStep] = useState('source');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSourceAvailable, setIsSourceAvailable] = useState(true);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const functionKey = process.env.REACT_APP_FUNCTION_KEY;
  const summarizeNewsEndpoint = process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT;
  const sourceCategoriesEndpoint = process.env.REACT_APP_NEWS_SOURCE_CATEGORIES_ENDPOINT;
  const userPreferencesEndpoint = process.env.REACT_APP_USER_PREFERENCES_ENDPOINT;

  const summarizeNewsUrl = `${apiBaseUrl}${summarizeNewsEndpoint}?code=${encodeURIComponent(functionKey)}`;
  const sourceCategoriesUrl = `${apiBaseUrl}${sourceCategoriesEndpoint}?code=${encodeURIComponent(functionKey)}`;
  const userPreferencesUrl = `${apiBaseUrl}${userPreferencesEndpoint}`;;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserInfo = useCallback(async (userId) => {
    try {
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_USER_PREFERENCES_ENDPOINT}`,
        {
          method: 'GET'
        }
      );

      if (response.status === 404) {
        setIsReturningUser(false);
      } else if (response.ok) {
        setIsReturningUser(true);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setIsReturningUser(false);
    }
  }, []);

  useEffect(() => {
    if (isDemoMode) {
      setIsReturningUser(true);
      setFirstName('Jane');
      setUserId('demo-user-123');
      setSelectedCategories(['nz-news', 'world-news']);
      setSelectedSource('Stuff');
      return;
    }

    if (user && user.uid) {
      fetchUserInfo(user.uid);
    }
  }, [user, fetchUserInfo, isDemoMode]);

  const getFirstNameFromUser = (user) => {
    if (user.displayName) {
      return user.displayName.split(' ')[0];
    } else if (user.email) {
      return user.email.split('@')[0];
    }
    return 'User';
  };

  useEffect(() => {
    if (selectedSource && currentStep === 'categories') {
      setLoadingCategories(true);
      setIsSourceAvailable(true);
      
      makeAuthenticatedRequest(
        process.env.REACT_APP_NEWS_SOURCE_CATEGORIES_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify({ news_source: selectedSource })
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.categories && data.categories.length > 0) {
            setCategories(data.categories);
          } else {
            setIsSourceAvailable(false);
          }
          setLoadingCategories(false);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
          setIsSourceAvailable(false);
          setLoadingCategories(false);
        });
    }
  }, [selectedSource, currentStep]);

  const SourceButton = ({ source, logo, isSelected, onClick }) => (
    <button 
      className={`source-button ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(source)}
    >
      <img src={logo} alt={`${source} logo`} />
      <span className="source-name">{source}</span>
    </button>
  );

  const handleSourceSelect = (source) => {
    setSelectedSource(source);
    setCurrentStep('categories');
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => {
      const newCategories = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      
      if (newCategories.length > 0) {
        setErrorMessage(''); // Clear error message when a category is selected
      }
      
      return newCategories;
    });
  };

  const handleSubmit = async () => {
    if (selectedCategories.length === 0) {
      setErrorMessage('Please select at least one category before submitting.');
      return;
    }
    
    if (!userId && !isDemoMode) {
      setErrorMessage('You must be signed in to submit preferences.');
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    try {
      // For demo mode, use mock data
      if (isDemoMode) {
        const mockData = {
          summarized_news: "This is a demo summary of the news...",
          audio_file_url: "https://example.com/demo-audio.mp3",
          articles: [
            { title: "Demo Article 1", link: "https://example.com/article1" },
            { title: "Demo Article 2", link: "https://example.com/article2" }
          ],
          timestamps: [
            { title: "Section 1", start_time: 0 },
            { title: "Section 2", start_time: 30 }
          ]
        };

        navigate('/play-summary', { state: mockData });
        return;
      }

      const dataToSend = {
        user_id: userId,
        categories: selectedCategories,
        sources: [selectedSource],
      };

      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify(dataToSend)
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Navigate to PlaySummary with the data
      navigate('/play-summary', { 
        state: { 
          summarized_news: data.summarized_news, 
          audio_file_url: data.audio_file_url,
          articles: data.articles,
          timestamps: data.timestamps
        } 
      });
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while processing your request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatCategoryLabel = (category) => {
    return category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleBackToSource = () => {
    setCurrentStep('source');
  };

  const renderCategorySelection = () => (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={handleBackToSource} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h2">
          Select Categories for {selectedSource}
        </Typography>
      </Box>
      
      {loadingCategories ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : !isSourceAvailable ? (
        <Paper elevation={3} sx={{ p: 3, mt: 2, backgroundColor: 'error.light' }}>
          <Typography variant="body1">
            We're sorry, but {selectedSource} is currently unavailable.
          </Typography>
          <Typography variant="body1">
            Please check back later or try another source.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setCurrentStep('source')}
            sx={{ mt: 2 }}
          >
            Try Another Source
          </Button>
        </Paper>
      ) : categories.length > 0 ? (
        <Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {MAIN_CATEGORIES.filter(cat => categories.includes(cat)).map((category) => (
              <Chip
                key={category}
                label={formatCategoryLabel(category)}
                onClick={() => handleCategoryToggle(category)}
                color={selectedCategories.includes(category) ? "primary" : "default"}
                variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                clickable
                sx={{
                  '&:hover': {
                    backgroundColor: selectedCategories.includes(category) ? 'primary.dark' : 'action.hover',
                  },
                }}
              />
            ))}
          </Box>
          {categories.some(cat => !MAIN_CATEGORIES.includes(cat)) && (
            <Box>
              <Button 
                onClick={() => setShowOtherCategories(!showOtherCategories)}
                sx={{ mb: 1 }}
              >
                {showOtherCategories ? 'Hide Other Categories' : 'Show Other Categories'}
              </Button>
              {showOtherCategories && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {categories
                    .filter(cat => !MAIN_CATEGORIES.includes(cat))
                    .map((category) => (
                      <Chip
                        key={category}
                        label={formatCategoryLabel(category)}
                        onClick={() => handleCategoryToggle(category)}
                        color={selectedCategories.includes(category) ? "primary" : "default"}
                        variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                        clickable
                        sx={{
                          '&:hover': {
                            backgroundColor: selectedCategories.includes(category) ? 'primary.dark' : 'action.hover',
                          },
                        }}
                      />
                    ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : null}
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      {isSourceAvailable && (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit} 
          disabled={selectedCategories.length === 0 || isLoading}
          sx={{ mt: 3 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Regenerate CustomCast'}
        </Button>
      )}
    </Box>
  );

  const renderSourceSelection = () => (
    <section className="sources-section">
      <h2>Select a Source</h2>
      <div className="source-buttons">
        <SourceButton 
          source="Stuff"
          logo={stuffLogo}
          isSelected={selectedSource === 'Stuff'}
          onClick={handleSourceSelect}
        />
        <SourceButton 
          source="The Spinoff"
          logo={spinoffLogo}
          isSelected={selectedSource === 'The Spinoff'}
          onClick={handleSourceSelect}
        />
      </div>
    </section>
  );

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      // The onAuthStateChanged listener in App.js will handle the state update
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleRestartOnboarding = () => {
    navigate('/onboarding');
  };

  if (isLoading) {
    return <LoadingScreen selectedSource={selectedSource} selectedCategories={selectedCategories} />;
  }

  return (
    <>
      <MenuBar />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {currentStep === 'source' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {isReturningUser ? `Welcome back, ${firstName}!` : `Welcome${firstName}!`}
            </Typography>
            
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={handleRestartOnboarding}
            >
              Restart Onboarding
            </Button>
          </Box>
        )}

        {currentStep === 'source' && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h5" gutterBottom>Select a Source</Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Card>
                  <CardContent>
                    <img src={stuffLogo} alt="Stuff logo" style={{ width: 100, height: 100 }} />
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => handleSourceSelect('Stuff')}>Stuff</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <img src={spinoffLogo} alt="The Spinoff logo" style={{ width: 100, height: 100 }} />
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => handleSourceSelect('The Spinoff')}>The Spinoff</Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === 'categories' && renderCategorySelection()}
        
      </Container>
    </>
  );
};

export default Home;
