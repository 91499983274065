import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

let demoCredentials = null;

export const getDemoCredentials = async () => {
  try {
    // If we already have credentials, return them
    if (demoCredentials) {
      return demoCredentials;
    }

    // Otherwise authenticate with Firebase
    const userCredential = await signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_DEMO_USER_EMAIL,
      process.env.REACT_APP_DEMO_USER_PASSWORD
    );

    // Get both token and uid
    const token = await userCredential.user.getIdToken();
    const uid = userCredential.user.uid;

    // Store both credentials
    demoCredentials = { token, uid };
    return demoCredentials;
  } catch (error) {
    console.error('Demo authentication failed:', error);
    throw error;
  }
};
