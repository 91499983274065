import { auth } from '../firebase/firebaseConfig';
import { getDemoCredentials } from './demoAuthService';

export const makeAuthenticatedRequest = async (endpoint, options = {}) => {
  try {
    let token;
    let userId;

    if (options.isDemoMode) {
      const demoCredentials = await getDemoCredentials();
      token = demoCredentials.token;
      userId = demoCredentials.uid;
    } else {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }
      token = await currentUser.getIdToken();
      userId = currentUser.uid;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUBSCRIPTION_KEY,
      'Authorization': `Bearer ${token}`
    };

    if (!options.isDemoMode && (
      endpoint.includes('/UserState') || 
      endpoint.includes('/UserPreferences') 
    )) {
      headers['X-MS-CLIENT-PRINCIPAL-ID'] = userId;
    }

    const finalHeaders = {
      ...headers,
      ...(options.headers || {})
    };

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
      ...options,
      headers: finalHeaders,
      mode: 'cors'
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    throw error;
  }
};
