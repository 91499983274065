import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { trackEvent } from './utils/analytics';
import { formatCategoryList } from './utils';

const TypeWriter = ({ text, delay, onComplete }) => {
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    let timeoutId;
    const typeNextChar = (index) => {
      if (index < text.length) {
        setCurrentText(text.slice(0, index + 1));
        timeoutId = setTimeout(() => typeNextChar(index + 1), delay);
      } else if (onComplete) {
        timeoutId = setTimeout(onComplete, 500);
      }
    };

    typeNextChar(0);
    return () => clearTimeout(timeoutId);
  }, [text, delay, onComplete]);

  return <span>{currentText}</span>;
};

const LoadingScreen = ({ selectedSource, selectedCategories, isDemoMode }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const MESSAGES = {
    demo: [
      'Generating Your Customcast.',
      `Creating a summary of ${formatCategoryList(selectedCategories)} from ${selectedSource}.`,
      'Fetching latest news articles...',
      'Analyzing content...',
      'Generating audio summary...'
    ],
    regular: [
      'Generating Your Custom Cast Module.',
      `We're updating your module with your selected news categories: ${formatCategoryList(selectedCategories)} from ${selectedSource}.`,
      'Fetching latest news articles...',
      'Analyzing content...',
      'Generating audio summary...'
    ]
  };

  const messages = isDemoMode ? MESSAGES.demo : MESSAGES.regular;

  const handleMessageComplete = useCallback(() => {
    if (currentMessageIndex < messages.length - 1) {
      const delay = isDemoMode ? 1500 : 800;
      setTimeout(() => {
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
      }, delay);
    }
  }, [currentMessageIndex, messages.length, isDemoMode]);


  useEffect(() => {
    trackEvent('generation_progress', {
      stage: 'start'
    });

    return () => {
      trackEvent('generation_progress', {
        stage: 'complete'
      });
    };
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '200px'
    }}>
      <Typography 
        variant={currentMessageIndex === 0 ? "body1" : "body1"} 
        gutterBottom
        sx={{ 
          textAlign: 'center',
          minHeight: '3em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <TypeWriter 
          text={messages[currentMessageIndex]} 
          delay={isDemoMode ? 50 : 40}
          onComplete={handleMessageComplete}
        />
      </Typography>
      <CircularProgress sx={{ mt: 4 }} />
    </Box>
  );
};

export default LoadingScreen;
